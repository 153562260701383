// utils.js

export function formatDate(dateString) {
  const options = { year: "numeric", month: "long", day: "numeric" };
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString(undefined, options);

  const monthMapping = {
    January: "Janeiro",
    February: "Fevereiro",
    March: "Março",
    April: "Abril",
    May: "Maio",
    June: "Junho",
    July: "Julho",
    August: "Agosto",
    September: "Setembro",
    October: "Outubro",
    November: "Novembro",
    December: "Dezembro",
  };

  // Replace the English month with the Portuguese month
  const englishMonth = date.toLocaleDateString("en-US", { month: "long" });
  const portugueseMonth = monthMapping[englishMonth];

  return formattedDate.replace(englishMonth, portugueseMonth);
}
export function updateTitleAndMeta(article) {
  document.title = article.title;
  const metaDescription = document.querySelector('meta[name="description"]');
  if (metaDescription) {
    metaDescription.content = article.summary || article.takeaways;
  } else {
    const meta = document.createElement("meta");
    meta.name = "description";
    meta.content = article.summary || article.takeaways;
    document.head.appendChild(meta);
  }
}

export function removeSpecifiedNodes() {
  const nodesToRemove = ["#ad-banner", ".pop-up"];
  nodesToRemove.forEach((selector) => {
    const nodes = document.querySelectorAll(selector);
    nodes.forEach((node) => node.parentNode.removeChild(node));
  });
}
