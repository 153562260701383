// src/apiService.js
import axios from "axios";
import config from "./config";

const apiClient = axios.create({
  baseURL: config.apiDomain,
});

export const fetchArticles = (categorySlug, page) => {
  const endpoint = categorySlug
    ? `/teams/1/articles/category/${categorySlug}/?format=json&page=${page}`
    : `/teams/1/articles/?format=json&page=${page}`;
  return apiClient.get(endpoint);
};

export const fetchCategories = () => {
  return apiClient.get("/categories/?format=json");
};

export const fetchSubjects = () => {
  return apiClient.get("/subjects/?format=json");
};

// Add fetchSources function
export const fetchSources = () => {
  return apiClient.get("/sources/?format=json");
};
