import React from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


export function Footer() {
  
    return (
    <>
    <footer className='container-fluid footer'>
      <Row className='top-footer'>
        <Container>
          <Row className='my-10'>
            <Col className="col-md-12 col-lg-4 d-flex flex-column flex-lg-row align-items-center">
            <img className='logo-footer' alt='logo' src="/../img/Encefalites_Logo_Negativo.svg" />
            <ul className='list-unstyled align-items-start ps-lg-5 ps-0 footer-menu d-flex flex-row flex-lg-column justify-content-lg-start justify-content-center mb-0 '>
              <li className='pe-4 pe-lg-0'><Link to="https://encefalites.pt/quem-somos/">Contactos</Link></li>
              {/* <li className='pe-4 pe-lg-0'><Link to="#">Glossário</Link></li> */}
              <li className='pe-4 pe-lg-0'><Link to="https://encefalites.pt/site-map/">Site Map</Link></li>
            </ul>
            </Col>
            <Col className="col-md-12 offset-lg-3 col-lg-5 mt-6 mt-lg-0">
            <p className='h4 fw-bold text-white pb-2 d-flex justify-content-center justify-content-lg-start mb-4'>Financiado e apoiado por</p>
             <div className='d-flex justify-content-center justify-content-lg-start financiadores'>
             <img className='pe-4' alt='Santander' src="/../img/SantanderUn_Logo.svg" />
             <img className='pe-4' alt='Instituto de Investigação Interdisciplinar da Universidade de Coimbra' src="/../img/UCHorizontal_Logo.svg" />
             <img className='pe-4' alt='Projectos de investigação 2023' src="/../img/ProjetosDeInvestigacao_Logo.svg" />
             <img className='pe-4' alt='Sociedade Portuguesa de Neurologia' src="/../img/SociedadePortuguesaDeNeurologia_Logo.svg" />
              </div>
              <div className='d-flex justify-content-center justify-content-lg-start financiadores mt-5'>
             <img className='pe-4' alt='Centre for Innovative Biomedicine and Biotechnology' src="/../img/CIBB.svg" />
             <img className='pe-4' alt='Center for Neuroscience and Cell Biology' src="/../img/CNC.svg" />
              </div>
            </Col>
          </Row>
        </Container>
      </Row>
      <Row className='bottom-footer'>
        <Container>
          <Row>
            <Col className="col-12 col-md-6 col my-2">
            <p>© 2024 - Encefalite - todos os direitos reservados.</p>
            </Col>
            <Col className="col-12 col-md-6 col my-2">
            <p className='credits'>with <span> ♥ </span><a href="https://activemedia.pt/" target="_blank" rel="noreferrer noopener">.is/ActiveMedia</a></p>
            </Col>
          </Row>
        </Container>
      </Row>
    </footer>
    </>
    );
  }
  
  export default Footer;
  