import { useState, useEffect, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export function Header() {
  const [isScrolled, setIsScrolled] = useState(false);
  const navbarCollapseRef = useRef(null);
  const navbarToggleRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);

      // Collapse the menu if it is open
      if (navbarCollapseRef.current && navbarCollapseRef.current.classList.contains('show')) {
        navbarToggleRef.current.click();
      }
    };

    const handleResize = () => {
      // Collapse the menu if it is open
      if (navbarCollapseRef.current && navbarCollapseRef.current.classList.contains('show')) {
        navbarToggleRef.current.click();
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    // Clean up the event listeners on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <header>
      <Navbar fixed="top" expand="lg" className={isScrolled ? 'scrollnav mt-0 mt-sm-6 pt-0' : 'mt-0 mt-sm-6 pt-0'}>
        <Container className='logo-holder'>
          <Navbar.Brand href="/">
            <img
              src="/img/header-logo.svg"
              width="150"
              height="120"
              className={`align-top logo ${isScrolled ? 'scrolled' : ''}`}
              title="← Encefalites" 
              aria-label="Encefalites" 
              rel="home"
            />
            <img
              src="/img/header-logo-2.svg"
              width="150"
              height="67"
              className={`align-top logo-scroll ${isScrolled ? 'scrolled' : ''}`}
              title="← Encefalites" 
              aria-label="Encefalites" 
              rel="home"
              style={{ opacity: "0" }}
            />
          </Navbar.Brand>
          <Col className='nav-brand-text'>
            <div>Investigação, powered by <a href="https://gregory-ai.com/" target="_blank" rel="noreferrer">GregoryAI</a></div></Col>
          <Navbar.Toggle aria-controls="basic-navbar-nav" ref={navbarToggleRef} />
          <Navbar.Collapse id="basic-navbar-nav" ref={navbarCollapseRef}>
            <div className='menu-container'>
              <Row>
                <Col className="d-flex px-3 px-md-0">
            <Nav className="ms-auto align-items-end">
              <Nav.Link className='pe-0' href="https://encefalites.pt/">
                <Button variant="primary" size="sm">Voltar a Encefalites.pt</Button>
              </Nav.Link>
            </Nav>
          </Col>
          </Row>
          </div>
          
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;
