import React from 'react';
import Button from 'react-bootstrap/Button';
import { useAppContext } from '../context/AppContext';

const QueryDisplay = ({ description }) => {
  // Extract query from description (assumes query is within ```backticks```)
  const extractQuery = (desc) => {
    const match = desc.match(/```([\s\S]*?)```/);
    return match ? match[1].trim() : desc;
  };

  // Parse query into groups
  const parseQuery = (queryString) => {
    const groups = queryString.split('AND').map(group => 
      group.trim()
        .replace(/[()]/g, '')
        .split('OR')
        .map(term => term.trim())
        .filter(term => term)
    );
    return groups;
  };

  const query = extractQuery(description || '');
  const queryGroups = parseQuery(query);

  return (
    <div className="source-query border-top border-primary border-2 mt-5 mb-4">
      {queryGroups.map((group, groupIndex) => (
        <div key={groupIndex} className="mt-5">
          <div className="d-flex align-items-center mb-0">
            <span className="text-sm font-medium text-gray-700">
            <span className="d-none">{groupIndex + 1}</span>
            <span className="d-none"> {groupIndex === 0 }</span>
            <span className="d-none"> {groupIndex === 1 }</span>
            <span className="d-none"> {groupIndex === 2 }</span>
            </span>
            {groupIndex !== 0 && groupIndex < queryGroups.length && (
              <span className="mb-4 mt-n5 ml-2 px-2 py-1 andseparador">
                AND
              </span>
            )}
          </div>
          <div className="d-flex justify-content-start flex-wrap gap-2 ">
            {group.map((term, termIndex) => (
              <div key={termIndex} className="d-flex align-items-center">
              <Button
                size="xs"
                variant="outline-danger"
                className="categories-btn mb-2"
              >
                      {term}
              </Button>
                {termIndex < group.length - 1 && (
                  <small className="mb-2 ms-2 me-0">OR</small>
                )}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

const SourceList = () => {
  const { state } = useAppContext();
  const { sources, loading, error } = state;

  if (loading) return <div>Loading sources...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!sources || sources.length === 0) return <p>No sources available</p>;

  return (
    <div className="source-list space-y-6">
      {sources.map((source) => (
        <div key={source.source_id || source.name} className="source-item">
          <Button
            size="lg"
            variant="outline-danger"
            className="categories-btn"
            onClick={() => window.open(source.link, "_blank")}
          >
            {source.name}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-external-link"
              width="16"
              height="16"
              viewBox="0 0 22 22"
              strokeWidth="3"
              stroke="#2c3e50"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M12 6h-6a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-6" />
              <path d="M11 13l9 -9" />
              <path d="M15 4h5v5" />
            </svg>
          </Button>
          
          {source.description && <QueryDisplay description={source.description} />}
        </div>
      ))}
    </div>
  );
};

export default SourceList;