// src/components/PaginationComponent.jsx
import React from 'react';
import { Pagination } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export function PaginationComponent({ page, setPage, last_page, page_path }) {
  const navigate = useNavigate();

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
    navigate(`/${page_path}/${pageNumber}`);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const renderPaginationItems = () => {
    const items = [];
    const maxItems = 5; // Maximum number of pagination items to show
    const halfMaxItems = Math.floor(maxItems / 2);

    // If the number of pages is less than or equal to maxItems, show all pages
    if (last_page <= maxItems) {
      for (let number = 1; number <= last_page; number++) {
        items.push(
          <Pagination.Item key={number} active={number === page} onClick={() => handlePageChange(number)}>
            {number}
          </Pagination.Item>
        );
      }
    } else {
      // Add the first page
      items.push(
        <Pagination.Item key={1} active={page === 1} onClick={() => handlePageChange(1)}>
          1
        </Pagination.Item>
      );

      // Add an ellipsis if the current page is far from the beginning
      if (page > halfMaxItems + 2) {
        items.push(<Pagination.Ellipsis key="start-ellipsis" />);
      }

      // Calculate start and end pages
      const startPage = Math.max(2, page - halfMaxItems);
      const endPage = Math.min(last_page - 1, page + halfMaxItems);

      // Add the middle pages
      for (let number = startPage; number <= endPage; number++) {
        items.push(
          <Pagination.Item key={number} active={number === page} onClick={() => handlePageChange(number)}>
            {number}
          </Pagination.Item>
        );
      }

      // Add an ellipsis if the current page is far from the end
      if (page < last_page - halfMaxItems - 1) {
        items.push(<Pagination.Ellipsis key="end-ellipsis" />);
      }

      // Add the last page
      items.push(
        <Pagination.Item key={last_page} active={page === last_page} onClick={() => handlePageChange(last_page)}>
          {last_page}
        </Pagination.Item>
      );
    }

    return items;
  };

  // Return null if there is only one page
  if (last_page <= 1) {
    return null;
  }

  return (
    <Pagination size="sm">
      <Pagination.Prev onClick={() => page > 1 && handlePageChange(page - 1)} disabled={page === 1} />
      {renderPaginationItems()}
      <Pagination.Next onClick={() => page < last_page && handlePageChange(page + 1)} disabled={page === last_page} />
    </Pagination>
  );
}

export default PaginationComponent;
