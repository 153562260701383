// src/components/Sources.jsx
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useAppContext } from "../context/AppContext";
import SourceList from "./SourceList"; // Import the SourceList component
import SubjectList from "./SubjectList"; // Import the SubjectList component

const Sources = () => {
  const { state } = useAppContext();
  const { sources, subjects, loading, error } = state;

  if (loading) return <div>Loading sources...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <Container className="sources">
      <Row>
        <Col md={8} className="d-flex justify-start mt-9">
          <h3 className="fw-bold">
            Os artigos listados provêm dos seguintes sites, utilizando pesquisa
            por palavra-chave:{" "}
          </h3>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-start mt-5 sources-list mb-6">
          <SourceList sources={sources} /> {/* Use the SourceList component */}
        </Col>
      </Row>
    </Container>
  );
};

export default Sources;
