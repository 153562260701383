// src/App.js
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import ArticleList from "./pages/ArticleList";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { AppProvider, useAppContext } from "./context/AppContext";
import "./App.css";

const App = () => (
  <AppProvider>
    <Router>
      <Header />
      <Main />
      <Footer />
    </Router>
  </AppProvider>
);

const Main = () => {
  const location = useLocation();
  const {
    fetchAllArticles,
    fetchAllCategories,
    fetchAllSubjects,
    fetchAllSources,
  } = useAppContext();

  useEffect(() => {
    const pathParts = location.pathname.split("/");
    const page = pathParts.includes("page")
      ? parseInt(pathParts[pathParts.indexOf("page") + 1], 10)
      : 1;
    const categorySlug = pathParts.includes("category")
      ? pathParts[pathParts.indexOf("category") + 1]
      : null;

    fetchAllCategories();
    fetchAllSubjects();
    fetchAllSources();
    fetchAllArticles(categorySlug, page);
  }, [
    location,
    fetchAllArticles,
    fetchAllCategories,
    fetchAllSubjects,
    fetchAllSources,
  ]);

  return (
    <main className="flex-shrink-0 mt-12 pt-7 bg-color">
      <Routes>
        <Route path="/" element={<ArticleList page_path="page" />} />
        <Route
          path="/page/:pageNumber"
          element={<ArticleList page_path="page" />}
        />
        <Route
          path="/category/:categorySlug"
          element={<ArticleList page_path="category/:categorySlug/page" />}
        />
        <Route
          path="/category/:categorySlug/page/:pageNumber"
          element={<ArticleList page_path="category/:categorySlug/page" />}
        />
      </Routes>
    </main>
  );
};

export default App;
