// src/components/CategoriesList.jsx
import React from 'react';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import { useAppContext } from '../context/AppContext';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Categories = ({ onCategoryChange }) => {
  const { state } = useAppContext();
  const { categories, loading, error } = state;

  if (loading) return <div>Loading categories...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <Container className="categories">
      <Row className="mt-6 mb-4">
        <Col md={12}>
          <h2 className="categories-heading fw-bold">Filtre os artigos pelas seguintes categorias:</h2>
          <div className="categories-list mb-6">
            {categories.filter(category => category.article_count > 0).map((category) => (
              <Button
                size="xs"
                variant="outline-danger"
                className="categories-btn"
                key={category.id}
                onClick={() => onCategoryChange(category.category_slug)}
                aria-label={`Category ${category.category_name} with ${category.article_count} articles`}
              >
                {category.category_name} <Badge bg="primary">{category.article_count}</Badge>
                <span className="visually-hidden">categories number</span>
              </Button>
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Categories;
