import React, {
  createContext,
  useReducer,
  useContext,
  useCallback,
} from "react";
import {
  fetchArticles,
  fetchCategories,
  fetchSubjects,
  fetchSources,
} from "../apiService";

const AppContext = createContext();

const initialState = {
  articles: [],
  categories: [],
  subjects: [],
  sources: [],
  totalArticleCount: 0,
  loading: false,
  error: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_ARTICLES_SUCCESS":
      return {
        ...state,
        articles: action.payload.results,
        totalArticleCount: action.payload.count,
        loading: false,
      };
    case "FETCH_CATEGORIES_SUCCESS":
      return { ...state, categories: action.payload, loading: false };
    case "FETCH_SUBJECTS_SUCCESS":
      return { ...state, subjects: action.payload, loading: false };
    case "FETCH_SOURCES_SUCCESS":
      return { ...state, sources: action.payload, loading: false };
    case "SET_LOADING":
      return { ...state, loading: true };
    case "SET_ERROR":
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};

export const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchAllArticles = useCallback(async (categorySlug, page) => {
    dispatch({ type: "SET_LOADING" });
    try {
      const response = await fetchArticles(categorySlug, page);
      dispatch({ type: "FETCH_ARTICLES_SUCCESS", payload: response.data });
    } catch (error) {
      dispatch({ type: "SET_ERROR", payload: error.message });
    }
  }, []);

  const fetchAllCategories = useCallback(async () => {
    dispatch({ type: "SET_LOADING" });
    try {
      const response = await fetchCategories();
      dispatch({
        type: "FETCH_CATEGORIES_SUCCESS",
        payload: response.data.results,
      });
    } catch (error) {
      dispatch({ type: "SET_ERROR", payload: error.message });
    }
  }, []);

  const fetchAllSubjects = useCallback(async () => {
    dispatch({ type: "SET_LOADING" });
    try {
      const response = await fetchSubjects();
      dispatch({
        type: "FETCH_SUBJECTS_SUCCESS",
        payload: response.data.results,
      });
    } catch (error) {
      dispatch({ type: "SET_ERROR", payload: error.message });
    }
  }, []);

  const fetchAllSources = useCallback(async () => {
    dispatch({ type: "SET_LOADING" });
    try {
      const response = await fetchSources();
      dispatch({
        type: "FETCH_SOURCES_SUCCESS",
        payload: response.data.results,
      });
    } catch (error) {
      dispatch({ type: "SET_ERROR", payload: error.message });
    }
  }, []);

  return (
    <AppContext.Provider
      value={{
        state,
        fetchAllArticles,
        fetchAllCategories,
        fetchAllSubjects,
        fetchAllSources,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
